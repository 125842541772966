<template>
	<section class="dashboard mt-5">
		<div v-if="!onePagerReportData && !patientId">
			<b-message type="is-warning" has-icon>
				No patient reference provided.
			</b-message>
		</div>
		<div v-if="!onePagerReportData && !isLoading && patientId">
			<b-message type="is-warning" has-icon>
				No report data found.
			</b-message>
		</div>
		<section v-if="onePagerReportData">
			<b-button v-if="patientId" class="is-pulled-right print-button ml-3" @click="generateReport()" :disabled="isGeneratingPdf" :loading="isGeneratingPdf">Download report </b-button>
			<b-button v-if="patientId" class="is-pulled-right print-button ml-3" @click="copyReportUrl()">Copy report link</b-button>
			<knee-hip-report v-if="patientId" :patient-id="patientId" :couple-id="coupleId" :one-pager-report-data="onePagerReportData" :print-version="false"></knee-hip-report>
		</section>
	</section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import KneeHipReport from '../components/KneeHipReport';
import { successToast } from '../mixins';
import dayjs from 'dayjs';

export default {
	components: { KneeHipReport },
	mixins: [successToast],
	computed: {
		...mapState(['user']),
		isGeneratingPdf() {
			return this.generatingPDF;
		},
	},
	data() {
		return {
			isLoading: true,
			patientId: null,
			coupleId: null,
			generatingPDF: false,
			onePagerReportData: null,
			language: null,
		};
	},
	methods: {
		...mapActions(['getOnePagerReport', 'getOnePagerReportPDF']),
		async loadData() {
			try {
				if (!this.patientId && !this.coupleId) {
					return;
				}
				this.onePagerReportData = await this.getOnePagerReport({ patientId: this.patientId, coupleId: this.coupleId, language: this.language });
			} catch (err) {
				this.errorToast(err);
			} finally {
				this.isLoading = false;
			}
		},
		async generateReport() {
			try {
				this.generatingPDF = true;
				if (this.onePagerReportData) {
					let result = await this.getOnePagerReportPDF({ patientId: this.patientId, coupleId: this.coupleId, language: this.language });
					const file = new Blob([result], { type: 'application/pdf' });
					const link = document.createElement('a');
					link.href = URL.createObjectURL(file);
					let reportDate = dayjs(new Date()).format('DD-MM-YYYY');
					let patientIdentifier = this.onePagerReportData['patient_number'] ? this.onePagerReportData['patient_number'] : this.patientId;
					let reportName = `Patient Report - Date ${reportDate} - Nr ${patientIdentifier}.pdf`;
					link.download = reportName;
					link.click();
					URL.revokeObjectURL(link.href);
				}
			} catch (err) {
				this.errorToast(err);
			} finally {
				this.generatingPDF = false;
			}
		},
		async copyReportUrl() {
			await navigator.clipboard.writeText(`${window.location.origin}${this.$route.path}?patientId=${this.patientId}&coupleId=${this.coupleId}`);
			this.successToast('Report link copied!');
		},
		hasGenerated() {
			this.generatingPDF = false;
		},
	},
	mounted() {
		if (this.$route.query.patientId) this.patientId = this.$route.query.patientId;
		if (this.$route.query.coupleId) this.coupleId = this.$route.query.coupleId;
		if (this.$route.query.language) this.language = this.$route.query.language;

		if (this.language) {
			console.log(this.language);
			this.$language.current = this.language;
		}

		this.loadData();
	},
};
</script>
